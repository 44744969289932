import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import Loadable from "react-loadable";
import { PrivateRoute } from "./_component";
import "./App.scss";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import("./views/Pages/Login"),
  loading
});

const ForgotPassword = Loadable({
  loader: () => import("./views/Pages/ForgotPassword"),
  loading
});

const EMitraDownload = Loadable({
  loader: () => import("./views/EMitraCard/EMitraCardDownload"),
  loading
});

const ResetPassword = Loadable({
  loader: () => import("./views/Pages/ResetPassword"),
  loading
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading
});

const Page404 = Loadable({
  loader: () => import("./views/Pages/Page404"),
  loading
});

const Page500 = Loadable({
  loader: () => import("./views/Pages/Page500"),
  loading
});

// Konfirmasi
const Konfirmasi = Loadable({
  loader: () => import("./views/Pendaftaran/Daftar/Konfirmasi"),
  loading
});

// Invoice
const Invoice = Loadable({
  loader: () => import("./views/Pendaftaran/Daftar/Invoice"),
  loading
});

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword} />
          <Route exact path="/reset-password" name="Reset Password Page" component={ResetPassword} />
          <Route exact path="/e-mitra-download" name="E Mitra Download" component={EMitraDownload} />
          <Route
            exact
            path="/register"
            name="Register Page"
            component={Register}
          />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route
            exact
            path="/pendaftaran/konfirmasi"
            name="Konfirmasi"
            component={Konfirmasi}
          />
          <Route
            exact
            path="/pendaftaran/invoice"
            name="Invoice"
            component={Invoice}
          />
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;

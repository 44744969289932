
import imageCompression from 'browser-image-compression';


function isEmpty(obj) {
  return Object.getOwnPropertyNames(obj).length >= 1
}

function compress(fileImage) {
  var options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }
  try {
    return imageCompression(fileImage, options);
  } catch(error) {
    console.log("compress image failed")
    return new Promise()
  }
}

function download(data, fileName){
  let a = document.createElement("a");
  let url = window.URL.createObjectURL(data);

  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

function b64toBlob(b64Data, contentType='', sliceSize=512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

const rupiah = (number)=>{
  if(number){
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      }).format(number);
  }else{
    return 'Rp. 0'
  }

}

const numeral = (number) => {
  if(number){
    return new Intl.NumberFormat("id-ID", {
      maximumFractionDigits: 3,
      }).format(number);
  } else {
    return 0;
  }

}

export const utils = {
  isEmpty,
  compress,
  download,
  b64toBlob,
  rupiah,
  numeral,
}

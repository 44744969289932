import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React from "react";
// import * as Sentry from '@sentry/browser';
import ReactDOM from "react-dom";
import "./index.css";

import {Provider} from "react-redux";
import {store} from "./_helpers";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Sentry.init({dsn: "https://ace02efd34d447c5bbfb50fb80866966@sentry.io/1760616"});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
